import { Alert } from 'antd'
import moment from 'moment'
import { Link, Outlet, useParams } from 'react-router-dom'

import { useEventMeta } from '../hooks/useEventMeta'
import { useEventSubscription } from '../hooks/useEventSubscription'

export default function FreeTrialLimited() {
  const { eventId } = useParams()
  const { trialEnd } = useEventMeta(eventId)
  const { subscription, isLoading: isSubscriptionLoading, hasSucceedSubscription } = useEventSubscription(eventId)

  if (isSubscriptionLoading) return null

  if (subscription) return <Outlet />

  if (hasSucceedSubscription) {
    return (
      <Alert
        message="Your subscription has been expired."
        description={
          <p>
            Please <Link to="dashboard">subscribe</Link> to use this feature.
          </p>
        }
        type="warning"
      />
    )
  }

  return (
    <Alert
      message="You are currently on a Free Trial"
      description={
        <>
          <p>
            Please <Link to="dashboard">subscribe</Link> to use this feature. Trial ends on{' '}
            {moment(trialEnd).format('MMMM D, YYYY')}.
          </p>
        </>
      }
      type="info"
    />
  )
}
