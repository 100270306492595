import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import API from '../../services/api'
import { getToken, setToken, removeToken } from '../../utils/cookie'

const initialState = {
  isLoading: false,
  isAuthenticated: !!getToken(),
  user: null,
  error: null,
  registeringEmail: null,
  forgotPasswordEmail: null,
}

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password, remember = false }, { rejectWithValue }) => {
    try {
      const {
        data: { token, user },
      } = await API.post('auth/login', { email, password })
      setToken(token, remember)
      return user
    } catch (err) {
      return rejectWithValue({
        email,
        error: err?.response?.data,
      })
    }
  },
)

/**
 * Payload: { email, password, organizationName }
 */
export const register = createAsyncThunk('auth/register', async (payload, { rejectWithValue }) => {
  try {
    await API.post('auth/register', payload)
    return payload
  } catch (err) {
    return rejectWithValue(err?.response?.data)
  }
})

/**
 * Payload: { email }
 */
export const forgotPassword = createAsyncThunk('auth/request-reset', async (payload, { rejectWithValue }) => {
  try {
    await API.post('auth/request-reset', payload)
    return payload
  } catch (err) {
    return rejectWithValue(err?.response?.data || 'Something went wrong')
  }
})

/**
 * Payload: { email, password, code }
 */
export const resetPassword = createAsyncThunk('auth/reset-password', async (payload, { rejectWithValue }) => {
  try {
    const {
      data: { user },
    } = await API.post('auth/reset-password', payload)
    return user
  } catch (err) {
    return rejectWithValue(err?.response?.data)
  }
})

/**
 * Payload: { email }
 */
export const requestEmailCode = createAsyncThunk('auth/request-code', async (payload, { rejectWithValue }) => {
  try {
    await API.post('auth/request-code', payload)
  } catch (err) {
    return rejectWithValue(err?.response?.data)
  }
})

/**
 * Payload: { email, code }
 */
export const verifyEmail = createAsyncThunk('auth/verify-email', async (payload, { rejectWithValue }) => {
  try {
    const {
      data: { token, user },
    } = await API.post('auth/verify-email', payload)
    setToken(token)
    return user
  } catch (err) {
    return rejectWithValue(err?.response?.data)
  }
})

export const logout = createAsyncThunk('auth/logout', async (a, { rejectWithValue }) => {
  try {
    // await API.post('auth/logout')
    removeToken()
  } catch (err) {
    return rejectWithValue(err?.response?.data?.error)
  }
})

const isLoading = (action) => {
  return [
    'auth/login/pending',
    'auth/logout/pending',
    'auth/register/pending',
    'auth/verify-email/pending',
    'auth/request-reset/pending',
    'auth/reset-password/pending',
  ].includes(action.type)
}

const isFinishLoading = (action) => {
  return [
    'auth/login/fulfilled',
    'auth/login/rejected',
    'auth/logout/fulfilled',
    'auth/logout/rejected',
    'auth/register/fulfilled',
    'auth/register/rejected',
    'auth/verify-email/fulfilled',
    'auth/verify-email/rejected',
    'auth/request-reset/fulfilled',
    'auth/request-reset/rejected',
    'auth/reset-password/fulfilled',
    'auth/reset-password/rejected',
  ].includes(action.type)
}

const isRejected = (action) => {
  return [
    'auth/logout/rejected',
    'auth/register/rejected',
    'auth/verify-email/rejected',
    'auth/request-reset/rejected',
    'auth/reset-password/rejected',
  ].includes(action.type)
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload
        state.isAuthenticated = true
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null
        state.isAuthenticated = false
      })
      .addCase(register.fulfilled, (state, action) => {
        state.registeringEmail = action.payload.email
        state.error = null
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.user = action.payload
        state.isAuthenticated = true
        state.registeringEmail = null
        state.error = null
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.forgotPasswordEmail = action.payload.email
        state.error = null
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.forgotPasswordEmail = null
        state.error = null
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.payload.error
        if (action.payload.error?.code === 'EmailNotVerified') {
          state.registeringEmail = action.payload.email
        }
      })
      .addMatcher(isLoading, (state) => {
        state.isLoading = true
      })
      .addMatcher(isRejected, (state, action) => {
        state.error = action.payload
      })
      .addMatcher(isFinishLoading, (state) => {
        state.isLoading = false
      })
  },
})

export default authSlice.reducer
