import { Card } from 'antd'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { selectIsAuthenticated } from '../../store/Auth/selectors'

import './styles.less'

const AuthLayout = () => {
  const { state } = useLocation()
  const isAuthenticated = useSelector(selectIsAuthenticated)

  if (isAuthenticated) {
    return <Navigate to={state?.from?.pathname || '/home'} />
  }

  return (
    <div className="auth-layout">
      <Card className="form-container">
        <Outlet />
      </Card>
    </div>
  )
}

export default AuthLayout
