import React from 'react'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { ConfigProvider, theme } from 'antd'
import { createRoot } from 'react-dom/client'

import App from './App'
import './index.less'
import 'antd/dist/reset.css'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#00b96b',
      },
      algorithm: theme.darkAlgorithm,
    }}
  >
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </ConfigProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
