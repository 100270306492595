import { Layout } from 'antd'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom'

import SideBar from './SideBar'
import { useEventMeta } from '../../hooks/useEventMeta'
import { useEventSubscription } from '../../hooks/useEventSubscription'
import { selectIsAuthenticated } from '../../store/Auth/selectors'
import './styles.less'

const { Content } = Layout

const PortalLayout = () => {
  const location = useLocation()
  const { eventId } = useParams()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const { isOnFreeTrial } = useEventMeta(eventId)
  const { hasSucceedSubscription, isLoading } = useEventSubscription(eventId)

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  if (isLoading) {
    return (
      <Layout className="event-layout">
        <Layout className="site-layout">
          <Content className="site-content">Loading...</Content>
        </Layout>
      </Layout>
    )
  }

  if (!hasSucceedSubscription && !isOnFreeTrial && location.pathname !== `/event/${eventId}/dashboard`) {
    return <Navigate to={`/event/${eventId}/dashboard`} state={{ from: location }} />
  }

  return (
    <Layout className="event-layout">
      <SideBar />
      <Layout className="site-layout">
        <Content className="site-content">
          {/* {!isLoading && subscription && <EventBreadcrumb />} */}
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default PortalLayout
