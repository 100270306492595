import { Layout } from 'antd'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import PortalHeader from './PortalHeader'
import { useProfile } from '../../services/profileService'
import { selectIsAuthenticated } from '../../store/Auth/selectors'
import './styles.less'

const { Content } = Layout

const PortalLayout = () => {
  const location = useLocation()
  const { isLoading, isLoaded, error } = useProfile()
  const isAuthenticated = useSelector(selectIsAuthenticated)

  if (isLoading && !isLoaded) {
    return <div>Loading...</div>
  }

  if (!isAuthenticated || error) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  return (
    <Layout className="portal-layout">
      <PortalHeader />
      <Content className="portal-content">
        <Outlet />
      </Content>
    </Layout>
  )
}

export default PortalLayout
