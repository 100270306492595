import api, { useAPI } from './api'

// Get a subscriptionPlan record
export function useSubscriptionPlan(subscriptionPlanId) {
  return useAPI(`/subscription-plans/${subscriptionPlanId}`)
}

// Update a subscriptionPlan record
export function updateSubscriptionPlan(subscriptionPlanId, subscriptionPlan) {
  return api.put(`/subscription-plans/${subscriptionPlanId}`, subscriptionPlan)
}

// Update a subscriptionPlan record
export function batchUpdateSubscriptionPlans(subscriptionPlans) {
  return api.put(`/subscription-plans`, subscriptionPlans)
}

// Get list of subscriptionPlans
export function useSubscriptionPlans() {
  return useAPI(`/subscription-plans`)
}

// create a subscriptionPlan record
export function createSubscriptionPlan(subscriptionPlan) {
  return api.post(`/subscription-plans`, subscriptionPlan)
}

// delete a subscriptionPlan record
export function deleteSubscriptionPlan(subscriptionPlanId) {
  return api.delete(`/subscription-plans/${subscriptionPlanId}`)
}
