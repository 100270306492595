import { useProfile } from '../services/profileService'

export default function GuardedContent({ roles, children }) {
  const { data, isLoading } = useProfile()

  if (isLoading) return null

  if (!roles.includes(data.role)) return null

  return children
}
