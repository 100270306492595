import { useEventSubscription } from './useEventSubscription'
import { useEvent } from '../services/eventService'
import { useOrganization } from '../services/organizationService'

export function useEventMeta(eventId) {
  const { data: event, isLoading } = useEvent(eventId)
  const { data: organization } = useOrganization(event?.organizationId)

  const { trialStart, trialEnd, activeConfirmEmailTemplateId } = event?.meta || {}

  const isOnFreeTrial = trialEnd && new Date(trialEnd) > new Date()
  const isFreeTrialExpired = trialEnd && new Date(trialEnd) < new Date()
  const hadFreeTrial = !!trialStart
  const canDoFreeTrial = !organization?.meta?.freeTrialUsed

  return {
    trialStart,
    trialEnd,
    activeConfirmEmailTemplateId,
    isOnFreeTrial,
    isFreeTrialExpired,
    hadFreeTrial,
    canDoFreeTrial,
    isLoading,
  }
}
